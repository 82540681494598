<template>
  <EntityListMolecule :class="{ 'has-header': hasHeaderSlot, 'has-footer': hasFooterSlot }" :maxHeight="maxHeight">
    <header v-if="hasHeaderSlot">
      <slot name="header" />
    </header>
    <main>
      <slot />
    </main>
    <footer v-if="hasFooterSlot">
      <slot name="footer" />
    </footer>
  </EntityListMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const EntityListMolecule = styled('div')`
  padding: 0.5rem;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  background: ${({ theme }) => theme.colors.archonBlackTransparent};
  &.has-header {
    grid-template-rows: min-content 1fr;
  }
  &.has-footer {
    grid-template-rows: 1fr min-content;
  }
  &.has-header.has-footer {
    grid-template-rows: min-content 1fr min-content;
  }
  > main {
    padding: 0 6px 6rem 6px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 0.25rem;
    max-height: ${({ maxHeight }) => maxHeight}px;
    overflow: auto;
  }
`

export default {
  props: {
    maxHeight: {
      type: Number,
      default: 600,
    },
  },
  components: {
    EntityListMolecule,
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },
}
</script>
