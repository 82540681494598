<template>
  <GridMolecule :gap="gap" :columns="columns">
    <slot />
  </GridMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const GridMolecule = styled('div')`
  padding: ${({ gap }) => gap}rem;
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  grid-auto-rows: min-content;
  grid-gap: ${({ gap }) => gap}rem;
  justify-items: ${({ justifyItems }) => justifyItems};

  .spacer {
    min-height: ${({ gap }) => 3 * gap}rem;
  }

  .span-2 {
    grid-column: span 2;
  }
  .span-3 {
    grid-column: span 3;
  }
  .span-4 {
    grid-column: span 4;
  }
  .span-5 {
    grid-column: span 5;
  }
  .span-6 {
    grid-column: span 6;
  }
  .span-7 {
    grid-column: span 7;
  }
  .span-8 {
    grid-column: span 8;
  }
  .span-9 {
    grid-column: span 9;
  }
  .span-10 {
    grid-column: span 10;
  }
  .span-11 {
    grid-column: span 11;
  }
  .span-12 {
    grid-column: span 12;
  }

  .justify-center {
    justify-self: center;
  }
`

export default {
  props: {
    columns: {
      type: Number,
      default: 2,
    },
    padding: {
      type: Number,
      default: 0.5,
    },
    gap: {
      type: Number,
      default: 0.5,
    },
  },
  components: {
    GridMolecule,
  },
}
</script>
