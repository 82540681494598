var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SmallLargeAtom', [_c('strong', {
    staticClass: "small"
  }, [_vm._v(_vm._s(_vm.small))]), _c('strong', {
    staticClass: "large"
  }, [_vm._v(_vm._s(_vm.large))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }