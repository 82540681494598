<template>
  <DefaultEntityListItemAtom>
    <router-link v-if="item.linkTo" :to="item.linkTo">
      <TextOverflowEllipsisAtom class="label" :text="label ?? item.id" />
    </router-link>
    <TextOverflowEllipsisAtom v-else class="label" :text="label ?? item.id" />
    <IconButtonAtom v-if="hasRemove" @click="$emit('remove')">
      <XIcon size="16" />
    </IconButtonAtom>
  </DefaultEntityListItemAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { XIcon } from 'vue-feather-icons'

import { TextOverflowEllipsisAtom } from '@common/components'

import IconButtonAtom from '@/components/Atomic/Atoms/IconButtonAtom'

const DefaultEntityListItemAtom = styled('div')`
  border-radius: 3px;
  padding: 0.25rem;
  background: ${({ theme }) => theme.colors.archonBlueTransparent};
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content;
  align-items: center;
  > .label {
    overflow: hidden;
  }
`

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    hasRemove: {
      type: Boolean,
      default: true,
    },
    linkTo: {
      type: Object,
    },
  },
  components: {
    DefaultEntityListItemAtom,
    TextOverflowEllipsisAtom,
    IconButtonAtom,
    XIcon,
  },
  computed: {
    label() {
      return this.item[this.labelKey]
    },
  },
}
</script>
