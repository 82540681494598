<template>
  <OrganizationAcquisitionsOrganism :organizationId="organizationId" />
</template>

<script>
import OrganizationAcquisitionsOrganism from './Organisms/OrganizationAcquisitionsOrganism'

export default {
  components: {
    OrganizationAcquisitionsOrganism,
  },
  computed: {
    organizationId() {
      return this.$route?.params?.id
    },
  },
}
</script>
