<template>
  <SmallLargeAtom>
    <strong class="small">{{ small }}</strong>
    <strong class="large">{{ large }}</strong>
  </SmallLargeAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const SmallLargeAtom = styled('div')`
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.archonBlackTransparent};
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 0.25rem;
  .large {
    text-align: center;
    font-size: 2rem;
  }
`

export default {
  props: {
    small: {
      type: String,
      required: true,
    },
    large: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    SmallLargeAtom,
  },
}
</script>
