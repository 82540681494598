var render = function () {
  var _vm$label, _vm$label2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DefaultEntityListItemAtom', [_vm.item.linkTo ? _c('router-link', {
    attrs: {
      "to": _vm.item.linkTo
    }
  }, [_c('TextOverflowEllipsisAtom', {
    staticClass: "label",
    attrs: {
      "text": (_vm$label = _vm.label) !== null && _vm$label !== void 0 ? _vm$label : _vm.item.id
    }
  })], 1) : _c('TextOverflowEllipsisAtom', {
    staticClass: "label",
    attrs: {
      "text": (_vm$label2 = _vm.label) !== null && _vm$label2 !== void 0 ? _vm$label2 : _vm.item.id
    }
  }), _vm.hasRemove ? _c('IconButtonAtom', {
    on: {
      "click": function click($event) {
        return _vm.$emit('remove');
      }
    }
  }, [_c('XIcon', {
    attrs: {
      "size": "16"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }