<template>
  <PopoverListMolecule>
    <VPopover :placement="'right-end'" :open.sync="isActive">
      <slot name="trigger" />
      <template v-slot:popover>
        <PopoverListContent>
          <EntityListMolecule>
            <LabelAtom slot="header" :label="label" />
            <DefaultEntityListItemAtom v-for="item in items" :key="item.id" :item="item" :labelKey="itemLabelKey" :hasRemove="false" />
          </EntityListMolecule>
        </PopoverListContent>
      </template>
    </VPopover>
  </PopoverListMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { VPopover } from 'v-tooltip'

import LabelAtom from '@/components/Atomic/Atoms/LabelAtom'
import DefaultEntityListItemAtom from '@/components/Atomic/Atoms/DefaultEntityListItemAtom'
import EntityListMolecule from '@/components/Atomic/Molecules/EntityListMolecule'

const PopoverListMolecule = styled('div')`
  > .v-popover {
    display: inline-block;
    > .trigger {
      cursor: pointer;
      display: inline-block;
      color: ${({ theme }) => theme.colors.archonBlue};
    }
  }
`

const PopoverListContent = styled('div')`
  background: ${({ theme }) => theme.colors.archonBlack};
  border-radius: 0.5rem;
`

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemLabelKey: {
      type: String,
      default: 'label',
    },
  },
  components: {
    PopoverListMolecule,
    VPopover,
    EntityListMolecule,
    PopoverListContent,
    LabelAtom,
    DefaultEntityListItemAtom,
  },
  data() {
    return {
      isActive: false,
    }
  },
}
</script>
