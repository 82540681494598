var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EntityListMolecule', {
    class: {
      'has-header': _vm.hasHeaderSlot,
      'has-footer': _vm.hasFooterSlot
    },
    attrs: {
      "maxHeight": _vm.maxHeight
    }
  }, [_vm.hasHeaderSlot ? _c('header', [_vm._t("header")], 2) : _vm._e(), _c('main', [_vm._t("default")], 2), _vm.hasFooterSlot ? _c('footer', [_vm._t("footer")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }