var render = function () {
  var _vm$organization, _vm$organization2, _vm$uniqInheritedPerm, _vm$uniqInheritedAsse, _vm$uniqInheritedWidg, _vm$uniqManualPermiss, _vm$uniqManualAssetDi, _vm$uniqManualWidgetT, _vm$uniqPermissions, _vm$permissions, _vm$uniqAssetDimensio, _vm$assetDimensions, _vm$uniqWidgetTypes, _vm$widgetTypes;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _vm.error ? _c('ErrorAtom', {
    attrs: {
      "error": _vm.error
    }
  }) : _c('PanelMolecule', {
    attrs: {
      "title": _vm.title,
      "count": _vm.count,
      "hasMargin": true
    }
  }, [_c('template', {
    slot: "main"
  }, [_c('GridMolecule', {
    attrs: {
      "columns": 6
    }
  }, [false ? _c('EditableFieldMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Id',
      "value": (_vm$organization = _vm.organization) === null || _vm$organization === void 0 ? void 0 : _vm$organization.id,
      "isIdLike": true,
      "hasCopyToClipboard": true
    }
  }) : _vm._e(), false ? _c('EditableFieldMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Name',
      "value": (_vm$organization2 = _vm.organization) === null || _vm$organization2 === void 0 ? void 0 : _vm$organization2.name,
      "hasCopyToClipboard": true
    }
  }) : _vm._e(), false ? _c('div', {
    staticClass: "spacer span-2"
  }) : _vm._e(), _c('div', {
    staticClass: "spacer span-6"
  }), _c('PopoverListMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Inherited Permissions',
      "items": _vm.uniqInheritedPermissions,
      "itemLabelKey": 'name'
    }
  }, [_c('SmallLargeAtom', {
    attrs: {
      "slot": "trigger",
      "small": 'Inherited Permissions',
      "large": (_vm$uniqInheritedPerm = _vm.uniqInheritedPermissions) === null || _vm$uniqInheritedPerm === void 0 ? void 0 : _vm$uniqInheritedPerm.length
    },
    slot: "trigger"
  })], 1), _c('PopoverListMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Inherited AssetDimensions',
      "items": _vm.uniqInheritedAssetDimensions,
      "itemLabelKey": 'name'
    }
  }, [_c('SmallLargeAtom', {
    attrs: {
      "slot": "trigger",
      "small": 'Inherited AssetDimensions',
      "large": (_vm$uniqInheritedAsse = _vm.uniqInheritedAssetDimensions) === null || _vm$uniqInheritedAsse === void 0 ? void 0 : _vm$uniqInheritedAsse.length
    },
    slot: "trigger"
  })], 1), _c('PopoverListMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Inherited WidgetTypes',
      "items": _vm.uniqInheritedWidgetTypes,
      "itemLabelKey": 'name'
    }
  }, [_c('SmallLargeAtom', {
    attrs: {
      "slot": "trigger",
      "small": 'Inherited WidgetTypes',
      "large": (_vm$uniqInheritedWidg = _vm.uniqInheritedWidgetTypes) === null || _vm$uniqInheritedWidg === void 0 ? void 0 : _vm$uniqInheritedWidg.length
    },
    slot: "trigger"
  })], 1), _c('PopoverListMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Manual Permissions',
      "items": _vm.uniqManualPermissions,
      "itemLabelKey": 'name'
    }
  }, [_c('SmallLargeAtom', {
    attrs: {
      "slot": "trigger",
      "small": 'Manual Permissions',
      "large": (_vm$uniqManualPermiss = _vm.uniqManualPermissions) === null || _vm$uniqManualPermiss === void 0 ? void 0 : _vm$uniqManualPermiss.length
    },
    slot: "trigger"
  })], 1), _c('PopoverListMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Manual AssetDimensions',
      "items": _vm.uniqManualAssetDimensions,
      "itemLabelKey": 'name'
    }
  }, [_c('SmallLargeAtom', {
    attrs: {
      "slot": "trigger",
      "small": 'Manual AssetDimensions',
      "large": (_vm$uniqManualAssetDi = _vm.uniqManualAssetDimensions) === null || _vm$uniqManualAssetDi === void 0 ? void 0 : _vm$uniqManualAssetDi.length
    },
    slot: "trigger"
  })], 1), _c('PopoverListMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Manual WidgetTypes',
      "items": _vm.uniqManualWidgetTypes,
      "itemLabelKey": 'name'
    }
  }, [_c('SmallLargeAtom', {
    attrs: {
      "slot": "trigger",
      "small": 'Manual WidgetTypes',
      "large": (_vm$uniqManualWidgetT = _vm.uniqManualWidgetTypes) === null || _vm$uniqManualWidgetT === void 0 ? void 0 : _vm$uniqManualWidgetT.length
    },
    slot: "trigger"
  })], 1), _c('PopoverListMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Total Permissions',
      "items": _vm.uniqPermissions,
      "itemLabelKey": 'name'
    }
  }, [_c('SmallLargeAtom', {
    attrs: {
      "slot": "trigger",
      "small": 'Total Permissions',
      "large": "".concat((_vm$uniqPermissions = _vm.uniqPermissions) === null || _vm$uniqPermissions === void 0 ? void 0 : _vm$uniqPermissions.length, " / ").concat((_vm$permissions = _vm.permissions) === null || _vm$permissions === void 0 ? void 0 : _vm$permissions.length)
    },
    slot: "trigger"
  })], 1), _c('PopoverListMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Total AssetDimensions',
      "items": _vm.uniqAssetDimensions,
      "itemLabelKey": 'name'
    }
  }, [_c('SmallLargeAtom', {
    attrs: {
      "slot": "trigger",
      "small": 'Total AssetDimensions',
      "large": "".concat((_vm$uniqAssetDimensio = _vm.uniqAssetDimensions) === null || _vm$uniqAssetDimensio === void 0 ? void 0 : _vm$uniqAssetDimensio.length, " / ").concat((_vm$assetDimensions = _vm.assetDimensions) === null || _vm$assetDimensions === void 0 ? void 0 : _vm$assetDimensions.length)
    },
    slot: "trigger"
  })], 1), _c('PopoverListMolecule', {
    staticClass: "span-2 justify-center",
    attrs: {
      "label": 'Total WidgetTypes',
      "items": _vm.uniqWidgetTypes,
      "itemLabelKey": 'name'
    }
  }, [_c('SmallLargeAtom', {
    attrs: {
      "slot": "trigger",
      "small": 'Total WidgetTypes',
      "large": "".concat((_vm$uniqWidgetTypes = _vm.uniqWidgetTypes) === null || _vm$uniqWidgetTypes === void 0 ? void 0 : _vm$uniqWidgetTypes.length, " / ").concat((_vm$widgetTypes = _vm.widgetTypes) === null || _vm$widgetTypes === void 0 ? void 0 : _vm$widgetTypes.length)
    },
    slot: "trigger"
  })], 1), _c('TableMolecule', {
    staticClass: "span-6",
    attrs: {
      "columns": _vm.columns,
      "tableData": _vm.users
    }
  })], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }