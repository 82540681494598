<template>
  <TalpaLoaderWrapper v-if="$apollo.loading" />
  <ErrorAtom v-else-if="error" :error="error" />
  <PanelMolecule v-else :title="title" :count="count" :hasMargin="true">
    <template slot="main">
      <GridMolecule :columns="6">
        <EditableFieldMolecule
          v-if="false"
          class="span-2 justify-center"
          :label="'Id'"
          :value="organization?.id"
          :isIdLike="true"
          :hasCopyToClipboard="true"
        />
        <EditableFieldMolecule
          v-if="false"
          class="span-2 justify-center"
          :label="'Name'"
          :value="organization?.name"
          :hasCopyToClipboard="true"
        />
        <div v-if="false" class="spacer span-2" />
        <div class="spacer span-6" />
        <PopoverListMolecule
          class="span-2 justify-center"
          :label="'Inherited Permissions'"
          :items="uniqInheritedPermissions"
          :itemLabelKey="'name'"
        >
          <SmallLargeAtom slot="trigger" :small="'Inherited Permissions'" :large="uniqInheritedPermissions?.length" />
        </PopoverListMolecule>
        <PopoverListMolecule
          class="span-2 justify-center"
          :label="'Inherited AssetDimensions'"
          :items="uniqInheritedAssetDimensions"
          :itemLabelKey="'name'"
        >
          <SmallLargeAtom slot="trigger" :small="'Inherited AssetDimensions'" :large="uniqInheritedAssetDimensions?.length" />
        </PopoverListMolecule>
        <PopoverListMolecule
          class="span-2 justify-center"
          :label="'Inherited WidgetTypes'"
          :items="uniqInheritedWidgetTypes"
          :itemLabelKey="'name'"
        >
          <SmallLargeAtom slot="trigger" :small="'Inherited WidgetTypes'" :large="uniqInheritedWidgetTypes?.length" />
        </PopoverListMolecule>
        <PopoverListMolecule
          class="span-2 justify-center"
          :label="'Manual Permissions'"
          :items="uniqManualPermissions"
          :itemLabelKey="'name'"
        >
          <SmallLargeAtom slot="trigger" :small="'Manual Permissions'" :large="uniqManualPermissions?.length" />
        </PopoverListMolecule>
        <PopoverListMolecule
          class="span-2 justify-center"
          :label="'Manual AssetDimensions'"
          :items="uniqManualAssetDimensions"
          :itemLabelKey="'name'"
        >
          <SmallLargeAtom slot="trigger" :small="'Manual AssetDimensions'" :large="uniqManualAssetDimensions?.length" />
        </PopoverListMolecule>
        <PopoverListMolecule
          class="span-2 justify-center"
          :label="'Manual WidgetTypes'"
          :items="uniqManualWidgetTypes"
          :itemLabelKey="'name'"
        >
          <SmallLargeAtom slot="trigger" :small="'Manual WidgetTypes'" :large="uniqManualWidgetTypes?.length" />
        </PopoverListMolecule>
        <PopoverListMolecule class="span-2 justify-center" :label="'Total Permissions'" :items="uniqPermissions" :itemLabelKey="'name'">
          <SmallLargeAtom slot="trigger" :small="'Total Permissions'" :large="`${uniqPermissions?.length} / ${permissions?.length}`" />
        </PopoverListMolecule>
        <PopoverListMolecule
          class="span-2 justify-center"
          :label="'Total AssetDimensions'"
          :items="uniqAssetDimensions"
          :itemLabelKey="'name'"
        >
          <SmallLargeAtom
            slot="trigger"
            :small="'Total AssetDimensions'"
            :large="`${uniqAssetDimensions?.length} / ${assetDimensions?.length}`"
          />
        </PopoverListMolecule>
        <PopoverListMolecule class="span-2 justify-center" :label="'Total WidgetTypes'" :items="uniqWidgetTypes" :itemLabelKey="'name'">
          <SmallLargeAtom slot="trigger" :small="'Total WidgetTypes'" :large="`${uniqWidgetTypes?.length} / ${widgetTypes?.length}`" />
        </PopoverListMolecule>
        <TableMolecule class="span-6" :columns="columns" :tableData="users" />
      </GridMolecule>
    </template>
  </PanelMolecule>
</template>

<script>
import { TalpaLoaderWrapper, ErrorAtom } from '@common/components'
import SmallLargeAtom from '@/components/Atomic/Atoms//SmallLargeAtom'
import EditableFieldMolecule from '@/components/Atomic/Molecules/EditableFieldMolecule'
import PanelMolecule from '@/components/Atomic/Molecules/PanelMolecule'
import GridMolecule from '@/components/Atomic/Molecules//GridMolecule'
import TableMolecule from '@/components/Atomic/Molecules/TableMolecule'
import PopoverListMolecule from '@/components/Atomic/Molecules/PopoverListMolecule'

import ORGANIZATION_WITH_ACQUISITIONS_QUERY from '#/graphql/operations/organization/organisationWithAcquisitionsQuery.gql'
import ASSET_DIMENSIONS_QUERY from '#/graphql/assetDimensions/assetDimensionsBasic.gql'
import WIDGET_TYPES_QUERY from '#/graphql/widgetTypes/widgetTypes.gql'
import PERMISSIONS_QUERY from '#/graphql/permissions/permissionsBasic.gql'

export default {
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  components: {
    TalpaLoaderWrapper,
    ErrorAtom,
    PanelMolecule,
    GridMolecule,
    EditableFieldMolecule,
    SmallLargeAtom,
    TableMolecule,
    PopoverListMolecule,
  },
  data() {
    return {
      organization: null,
      error: null,
      uniqManualPermissionIds: new Set(),
      uniqInheritedPermissionIds: new Set(),
      uniqPermissionIds: new Set(),
      uniqManualAssetDimensionIds: new Set(),
      uniqInheritedAssetDimensionIds: new Set(),
      uniqAssetDimensionIds: new Set(),
      uniqManualWidgetTypeIds: new Set(),
      uniqInheritedWidgetTypeIds: new Set(),
      uniqWidgetTypeIds: new Set(),
      permissions: [],
      assetDimensions: [],
      widgetTypes: [],
      users: [],
      columnsBase: [
        {
          field: 'id',
          title: 'ID',
          align: 'left',
        },
        {
          field: 'username',
          title: 'Username',
          align: 'left',
        },
      ],
    }
  },
  computed: {
    columns() {
      const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')
      const base = this.columnsBase.map((m, i) => ({ ...m, key: alphabet[i] }))

      const other = ['Permission', 'AssetDimension', 'WidgetType']
        .flatMap(type => {
          return ['manual', 'missingManual', 'intersectingManual'].map(m => {
            const key = `${m}${type}Ids`
            const typeInstancesKey = type.charAt(0).toLowerCase() + type.slice(1) + 's'
            const title = `${(m.charAt(0).toUpperCase() + m.slice(1)).replace('Manual', '')} ${type}s`
            return {
              field: '',
              title,
              align: 'center',
              renderBodyCell: ({ row }) => {
                const items = row[key].map(id => this?.[typeInstancesKey].find(f => f.id === id) ?? { id, name: `deleted ${type}` }) ?? []
                return (
                  <PopoverListMolecule label={title} items={items} itemLabelKey={'name'}>
                    <span slot="trigger">{items.length}</span>
                  </PopoverListMolecule>
                )
              },
            }
          })
        })
        .map((m, i) => ({
          ...m,
          key: alphabet[i + base.length],
        }))
      return [...base, ...other]
    },
    title() {
      return `Acquisitions for ${this.organization?.name}`
    },
    count() {
      return this.organization?.acquiredProductTierLevels?.length ?? 0
    },
    uniqManualPermissions() {
      return Array.from(this.uniqManualPermissionIds).map(m => this.permissions.find(f => f.id === m) ?? { id: m.id, name: 'loading' })
    },
    uniqInheritedPermissions() {
      return Array.from(this.uniqInheritedPermissionIds).map(m => this.permissions.find(f => f.id === m) ?? { id: m.id, name: 'loading' })
    },
    uniqPermissions() {
      return Array.from(this.uniqPermissionIds).map(m => this.permissions.find(f => f.id === m) ?? { id: m.id, name: 'loading' })
    },
    uniqManualAssetDimensions() {
      return Array.from(this.uniqManualAssetDimensionIds).map(
        m => this.assetDimensions.find(f => f.id === m) ?? { id: m.id, name: 'loading' },
      )
    },
    uniqInheritedAssetDimensions() {
      return Array.from(this.uniqInheritedAssetDimensionIds).map(
        m => this.assetDimensions.find(f => f.id === m) ?? { id: m.id, name: 'loading' },
      )
    },
    uniqAssetDimensions() {
      return Array.from(this.uniqAssetDimensionIds).map(m => this.assetDimensions.find(f => f.id === m) ?? { id: m.id, name: 'loading' })
    },
    uniqManualWidgetTypes() {
      return Array.from(this.uniqManualWidgetTypeIds).map(m => this.widgetTypes.find(f => f.id === m) ?? { id: m.id, name: 'loading' })
    },
    uniqInheritedWidgetTypes() {
      return Array.from(this.uniqInheritedWidgetTypeIds).map(m => this.widgetTypes.find(f => f.id === m) ?? { id: m.id, name: 'loading' })
    },
    uniqWidgetTypes() {
      return Array.from(this.uniqWidgetTypeIds).map(m => this.widgetTypes.find(f => f.id === m) ?? { id: m.id, name: 'loading' })
    },
  },
  apollo: {
    organization: {
      query: ORGANIZATION_WITH_ACQUISITIONS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          where: {
            id: this.organizationId,
          },
        }
      },
      result({ data }) {
        const { uniqManualPermissionIds, uniqManualAssetDimensionIds, uniqManualWidgetTypeIds } = data?.organization?.memberships?.reduce(
          (acc, membership) => {
            membership.user.userPermissions.forEach(userPermission => acc.uniqManualPermissionIds.add(userPermission.permission.id))
            membership.user.userAssetDimensions.forEach(userAssetDimension =>
              acc.uniqManualAssetDimensionIds.add(userAssetDimension.assetDimension.id),
            )
            membership.user.userWidgetTypes.forEach(userWidgetType => acc.uniqManualWidgetTypeIds.add(userWidgetType.widgetType.id))
            return acc
          },
          {
            uniqManualPermissionIds: new Set(),
            uniqManualAssetDimensionIds: new Set(),
            uniqManualWidgetTypeIds: new Set(),
          },
        )
        this.uniqManualPermissionIds = uniqManualPermissionIds
        this.uniqManualAssetDimensionIds = uniqManualAssetDimensionIds
        this.uniqManualWidgetTypeIds = uniqManualWidgetTypeIds

        const { uniqInheritedPermissionIds, uniqInheritedAssetDimensionIds, uniqInheritedWidgetTypeIds } =
          data?.organization?.acquiredProductTierLevels?.reduce(
            (acc, acquiredProductTierLevel) => {
              acquiredProductTierLevel.productTierLevel.permissionIds.forEach(id => acc.uniqInheritedPermissionIds.add(id))
              acquiredProductTierLevel.productTierLevel.assetDimensionIds.forEach(id => acc.uniqInheritedAssetDimensionIds.add(id))
              acquiredProductTierLevel.productTierLevel.widgetTypeIds.forEach(id => acc.uniqInheritedWidgetTypeIds.add(id))
              return acc
            },
            {
              uniqInheritedPermissionIds: new Set(),
              uniqInheritedAssetDimensionIds: new Set(),
              uniqInheritedWidgetTypeIds: new Set(),
            },
          )
        this.uniqInheritedPermissionIds = uniqInheritedPermissionIds
        this.uniqInheritedAssetDimensionIds = uniqInheritedAssetDimensionIds
        this.uniqInheritedWidgetTypeIds = uniqInheritedWidgetTypeIds

        this.uniqPermissionIds = new Set([...this.uniqInheritedPermissionIds, ...this.uniqManualPermissionIds])
        this.uniqAssetDimensionIds = new Set([...this.uniqInheritedAssetDimensionIds, ...this.uniqManualAssetDimensionIds])
        this.uniqWidgetTypeIds = new Set([...this.uniqInheritedWidgetTypeIds, ...this.uniqManualWidgetTypeIds])

        const uniqManualPermissionIdArray = Array.from(uniqManualPermissionIds)
        const uniqInheritedPermissionIdArray = Array.from(uniqInheritedPermissionIds)
        const uniqManualAssetDimensionIdArray = Array.from(uniqManualAssetDimensionIds)
        const uniqInheritedAssetDimensionIdArray = Array.from(uniqInheritedAssetDimensionIds)
        const uniqManualWidgetTypeIdArray = Array.from(uniqManualWidgetTypeIds)
        const uniqInheritedWidgetTypeIdArray = Array.from(uniqInheritedWidgetTypeIds)

        this.users = data?.organization?.memberships?.map(membership => {
          const user = {
            id: membership.user.id,
            username: membership.user.username,
            manualPermissionIds: [...new Set(membership.user.userPermissions.map(m => m.permission.id))],
            missingManualPermissionIds: uniqManualPermissionIdArray.filter(
              uniqManualPermissionId => membership.user.userPermissions.find(f => f.permission.id === uniqManualPermissionId) === undefined,
            ),
            intersectingManualPermissionIds: uniqInheritedPermissionIdArray.filter(uniqManualPermissionId =>
              membership.user.userPermissions.find(f => f.permission.id === uniqManualPermissionId),
            ),
            manualAssetDimensionIds: [...new Set(membership.user.userAssetDimensions.map(m => m.assetDimension.id))],
            missingManualAssetDimensionIds: uniqManualAssetDimensionIdArray.filter(
              uniqManualAssetDimensionId =>
                membership.user.userAssetDimensions.find(f => f.assetDimension.id === uniqManualAssetDimensionId) === undefined,
            ),
            intersectingManualAssetDimensionIds: uniqInheritedAssetDimensionIdArray.filter(uniqManualAssetDimensionId =>
              membership.user.userAssetDimensions.find(f => f.assetDimension.id === uniqManualAssetDimensionId),
            ),
            manualWidgetTypeIds: [...new Set(membership.user.userWidgetTypes.map(m => m.widgetType.id))],
            missingManualWidgetTypeIds: uniqManualWidgetTypeIdArray.filter(
              uniqManualWidgetTypeId => membership.user.userWidgetTypes.find(f => f.widgetType.id === uniqManualWidgetTypeId) === undefined,
            ),
            intersectingManualWidgetTypeIds: uniqInheritedWidgetTypeIdArray.filter(uniqManualWidgetTypeId =>
              membership.user.userWidgetTypes.find(f => f.widgetType.id === uniqManualWidgetTypeId),
            ),
          }
          return user
        })
      },
    },
    permissions: {
      query: PERMISSIONS_QUERY,
    },
    assetDimensions: {
      query: ASSET_DIMENSIONS_QUERY,
    },
    widgetTypes: {
      query: WIDGET_TYPES_QUERY,
    },
  },
}
</script>
