var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PopoverListMolecule', [_c('VPopover', {
    attrs: {
      "placement": 'right-end',
      "open": _vm.isActive
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isActive = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('PopoverListContent', [_c('EntityListMolecule', [_c('LabelAtom', {
          attrs: {
            "slot": "header",
            "label": _vm.label
          },
          slot: "header"
        }), _vm._l(_vm.items, function (item) {
          return _c('DefaultEntityListItemAtom', {
            key: item.id,
            attrs: {
              "item": item,
              "labelKey": _vm.itemLabelKey,
              "hasRemove": false
            }
          });
        })], 2)], 1)];
      },
      proxy: true
    }])
  }, [_vm._t("trigger")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }